import { DefaultTheme } from 'styled-components';

const constProps: DefaultTheme = {
    headerHeightWeb: '7.5vh',
    headerHeightPercentRawWeb: 7.5,
    headerHeightMobile: '10vh',
    headerHeightPercentRawMobile: 7.5,
    headerHeightMobileUnit: 'vh',

    maxMobileWidth: '768px',
    maxMobileRawWidth: 768,

    mobileWidthPercent: '90%',
    mobileWidthPercentRaw: 90,

    webContentWidth: '70vw',

    fullDark: '#151515',
    primaryDark: '#202020',
    primaryLight: '#FFFFFF',
    primaryGrey: '#e5e5e5',
    darkerGrey: '#989898',
    gridGrey: '#D0D0D0',
    primaryBlue: '#00BCE8',
    primaryOrange: '#F78700',
    lightGrey: '#BFBBDA',
    transparentDark: '#060606',

    zIndexBackground: 0,
    zIndexGrid: 10,
    zIndexPage: 20,
    zIndexHeader: 50,

    logoCarouselNumberLogosDisplayedWeb: 6,

    titleTextMaxLineLength: 29,

    linkLegals: '/legals',

    greyExtension: 'Grey',

    maxRatio: 0.8,

    pageMarginTopWeb: '10vh',
    pageMarginTopWebRaw: 10,
    pageMarginBottomWeb: '10vh',
    pageMarginBottomWebRaw: 10,

    pageMarginBetweenContentsWeb: '15vh',
    pageMarginBetweenContentsWebUnit: 'vh',
    pageMarginBetweenContentsPercentRawWeb: 15,

    pageMarginBetweenContentsMobile: '10vh',
    pageMarginBetweenContentsMobileUnit: 'vh',
    pageMarginBetweenContentsPercentRawMobile: 10
};

export default constProps;
