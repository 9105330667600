import styled from 'styled-components';

interface Props {
    height?: number;
    isMobile: boolean;
    noMargin: boolean;
}

const StyledExpertise = styled.div<Props>`
    .pageSample {
        margin-top: ${({ theme }) =>
            `${theme.pageMarginBetweenContentsPercentRawMobile / 2 + 2}${theme.pageMarginBetweenContentsMobileUnit}`};
    }

    .sample-content {
    }

    .sample-content .text-container {
    }

    #expertise-content-techno {
        margin-top: ${({ theme }) => theme.pageMarginBetweenContentsMobile};
        display: flex;
        width: 100%;
        z-index: ${({ theme }) => theme.zIndexPage + 1};
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    #expertise-content-techno-card-container {
        margin-top: ${({ theme }) =>
            `${theme.pageMarginBetweenContentsPercentRawMobile / 2}${theme.pageMarginBetweenContentsMobileUnit}`};
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    #background-expertise-top {
        display: relative;
        top: 0;
        background-color: ${({ theme }) => theme.fullDark};
        z-index: ${({ theme }) => theme.zIndexBackground + 3};
        height: 65%;
        width: 100%;
    }

    #background-expertise-bottom {
        position: relative;
        background-color: ${({ theme }) => theme.primaryGrey};
        z-index: ${({ theme }) => theme.zIndexBackground + 2};
        height: 47%;
        width: 100%;
        bottom: 0;
    }

    @media only screen and (min-width: ${({ theme }) => theme.maxMobileRawWidth + 1}px) {
        .sample-content {
            height: 25%;
        }

        .sample-content .text-container {
            width: 67%;
        }

        .sample-content .title1-container {
            width: 28%;
            align-content: space-between;
        }

        #expertise-content-techno {
            margin-top: ${({ theme }) => theme.pageMarginBetweenContentsWeb};
            height: 40%;
        }

        #expertise-content-techno-card-container {
            margin-top: 2%;
            flex-direction: row;
            align-items: flex-start;
            margin-bottom: 0;
        }

        #background-expertise-top {
            width: 100%;
            height: 110%;
        }

        #background-expertise-bottom {
            width: 100%;
            height: 30%;
        }
    }
`;

export default StyledExpertise;
