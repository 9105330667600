function Lights(): JSX.Element {
  return (
    <>
      <ambientLight />
      <pointLight position={[10, 10, 0]} />
    </>
  );
}

export default Lights;
