import React from 'react';

import MobileHeader from './mobile';
import WebHeader from './web';
import { MyContext } from '../../page/Main';

export type infoType = {
  tel: string;
  mail: string;
  linkedinLink: string;
};

export type pagesType = { name: string; link: string; hover: string }[];

export type handleScrollMoveType = boolean;

export type resetHandleScrollMoveType = () => void;

export type isOnTopType = boolean;

type Props = {
  pages: pagesType;
  info: infoType;
  handleScrollMove: handleScrollMoveType;
  resetHandleScrollMove: resetHandleScrollMoveType;
  isOnTop: isOnTopType;
};

function Header(props: Props): JSX.Element {
  const { isMobile } = React.useContext(MyContext);

  return isMobile ? <MobileHeader {...props} /> : <WebHeader {...props} />;
}

export default Header;
