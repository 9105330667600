import * as THREE from 'three';
import { Line } from '@react-three/drei';
import constProps from '../../config/const';

type Props = {
  xRadius: number;
  zRadius: number;
};
function Ecliptic({ xRadius, zRadius }: Props): JSX.Element {
  const points: THREE.Vector3[] = [];
  for (let index = 0; index < 128; index += 1) {
    const angle = (index / 128) * 2 * Math.PI;
    const x = xRadius * Math.cos(angle);
    const z = zRadius * Math.sin(angle);
    points.push(new THREE.Vector3(x, 0, z));
  }

  points.push(points[0]);

  return (
    <>
      <Line points={points} lineWidth={70} alphaWrite={false} transparent opacity={0} depthWrite={false} />

      <Line points={points} color={constProps.lightGrey} lineWidth={0.25} alphaWrite />
    </>
  );
}

export default Ecliptic;
