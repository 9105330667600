import styled from 'styled-components';

interface Props {
    isOnTop: boolean;
    isMenuOpen: boolean;
}

const StyledHeader = styled.header<Props>`
    @media only screen and (max-width: ${({ theme }) => theme.maxMobileRawWidth}px) {
        ${({ isOnTop, theme, isMenuOpen }): string =>
            !isOnTop || isMenuOpen ? `background-color: ${theme.primaryDark}` : ''};
    }
    top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    align-self: flex-start;
    width: 100%;
    height: ${({ theme }) => theme.headerHeightMobile};
    z-index: ${({ theme }) => theme.zIndexHeader};

    a {
        color: ${({ theme }) => theme.primaryBlue};
        text-decoration: none;
    }

    a:visited {
        color: ${({ theme }) => theme.primaryOrange};
    }

    .header-logo {
        width: ${({ theme }) => theme.headerHeightPercentRawMobile * 0.75 * 3.2}vh;
        height: ${({ theme }) => theme.headerHeightPercentRawMobile * 0.75}vh;
    }

    @media only screen and (min-width: ${({ theme }) => theme.maxMobileRawWidth + 1}px) {
        background-color: ${({ theme }) => theme.fullDark};
        height: ${({ theme }) => theme.headerHeightWeb};
        justify-content: space-between;

        .header-logo {
            width: ${({ theme }) => theme.headerHeightPercentRawWeb * 0.75 * 3}vh;
            height: ${({ theme }) => theme.headerHeightPercentRawWeb * 0.7}vh;
        }

        .header-container {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            width: 30%;
        }

        .header-links {
            align-items: flex-start;
            padding-left: 1vw;
        }

        .header-info {
            align-items: center;
            justify-content: flex-end;
            padding-right: 1vw;
        }

        .header-text {
            letter-spacing: 0.05vw;
        }

        .header-info-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }

        .header-node {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }

        .header-node p {
            color: white;
            font-size: 0.9vw;
        }

        .header-icon {
            height: 1.1vw;
            width: 1.1vw;
            margin-right: 0.7vw;
        }

        .header-link-cursor {
            cursor: pointer;
        }

        .header-link {
            display: flex;
            width: 7vw;
        }

        .header-link:hover p {
            color: #666;
        }

        .header-activeLink {
        }

        #header-linkedin-icon-container {
            display: flex;
        }

        #header-linkedin-icon-container img {
            height: 1.5vw;
            width: 1.5vw;
        }

        /* filter generated with: https://codepen.io/sosuke/pen/Pjoqqp */
        #header-linkedin-icon:hover {
            filter: invert(31%) sepia(57%) saturate(2076%) hue-rotate(176deg) brightness(94%) contrast(103%);
        }
    }
`;

export default StyledHeader;
