import { useState } from 'react';
import Ecliptic from './Ecliptic';
import Planet from './Planet';

type Props = {
  planet: {
    color: string[];
    xRadius: number;
    zRadius: number;
    size: number;
    hasPopup: boolean;
    text: string[] | undefined[];
  };
};

function PlanetOnElipse({ planet: { color, xRadius, zRadius, size, hasPopup, text } }: Props): JSX.Element {
  const [state, setState] = useState({ action: 'none', theta: 0 });

  return (
    <>
      <mesh rotation={[Math.PI / 10, 0, Math.PI / 16]}>
        <Planet
          color={color[0]}
          xRadius={xRadius}
          zRadius={zRadius}
          size={size}
          hasPopup={hasPopup}
          text={text[0]}
          {...state}
        />
      </mesh>
      <mesh rotation={[Math.PI / 10, 0, Math.PI / 16]}>
        <Planet
          color={color[1]}
          xRadius={-xRadius}
          zRadius={-zRadius}
          size={size}
          hasPopup={hasPopup}
          text={text[1]}
          {...state}
        />
      </mesh>
      <mesh
        rotation={[Math.PI / 10, 0, Math.PI / 16]}
        onPointerOver={() => {
          setState((prevState) => {
            return { ...prevState, action: 'over' };
          });
        }}
        onPointerLeave={() =>
          setState((prevState) => {
            return { ...prevState, action: 'none' };
          })
        }
      >
        <Ecliptic xRadius={xRadius} zRadius={zRadius} />
      </mesh>
    </>
  );
}

export default PlanetOnElipse;
