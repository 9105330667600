import { isMenuOpenType, setIsMenuOpenType } from '../..';

import { StyledBurger } from './Burger.styled';

type Props = {
  open: isMenuOpenType;
  setOpen: setIsMenuOpenType;
};

function Burger({ open, setOpen }: Props): JSX.Element {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)} data-testid="mobile-header-burger">
      <div />
      <div />
      <div />
    </StyledBurger>
  );
}

export default Burger;
