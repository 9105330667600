import _ from 'lodash';
import { useState } from 'react';

type Props = {
  logos: { [key: string]: string };
  logosGrey?: { [key: string]: string };
};

const defaultLogoGrey = {};

function LogoCarousel({ logos, logosGrey = defaultLogoGrey }: Props): JSX.Element {
  const [logoHover, setLogoHover] = useState('');

  const onHover = (name: string): void => setLogoHover(name);
  const unsetHover = (): void => setLogoHover('');

  return (
    <div id="carousel-web-container">
      {_.map(logos, (logo: string, name: string): any => (
        <div key={name} className="carousel-cell" onMouseEnter={() => onHover(name)} onMouseLeave={unsetHover}>
          {logoHover !== name && name in logosGrey ? (
            <img
              className="carousel-cell-content carousel-cell-content-grey"
              src={logosGrey[name]}
              alt={`${logo}Grey`}
            />
          ) : (
            <img className="carousel-cell-content" src={logo} alt={`${logo}`} />
          )}
        </div>
      ))}
    </div>
  );
}

LogoCarousel.defaultProps = { logosGrey: defaultLogoGrey };

export default LogoCarousel;
