import { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Window from './utils/useWindow';
import constProps from './config/const';

import Legals from './page/Legals';
import Main from './page/Main';

function App(): JSX.Element {
  const [prevScrollpos, setPrevScrollpos] = useState(window.pageYOffset);
  const [isOnTop, setIsOnTop] = useState(true);
  const [handleScrollMove, setHandleScrollMove] = useState(false);
  const { height, width, isMobile } = Window();

  const resetHandleScrollMove = (): void => setHandleScrollMove(false);

  const getHeaderHeightPx = useCallback(
    (): number =>
      Math.round(isMobile ? constProps.headerHeightPercentRawMobile : constProps.headerHeightPercentRawWeb) *
        (height / 100) -
      3,
    [isMobile, height]
  );

  const [headerHeightPx, setHeaderHeightPx] = useState(getHeaderHeightPx());

  useEffect(() => {
    setHeaderHeightPx(getHeaderHeightPx());
  }, [getHeaderHeightPx]);

  window.onscroll = () => {
    const currentScrollPos = window.pageYOffset;
    if (prevScrollpos !== currentScrollPos) {
      setHandleScrollMove(true);
    }
    if (window.pageYOffset === 0) {
      setIsOnTop(true);
    } else {
      setIsOnTop(false);
    }
    setPrevScrollpos(currentScrollPos);
  };

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Main
              isOnTop={isOnTop}
              handleScrollMove={handleScrollMove}
              winWidth={width}
              headerHeightPx={headerHeightPx}
              resetHandleScrollMove={resetHandleScrollMove}
              winHeight={height}
              isMobile={isMobile}
            />
          }
        />
        <Route path={constProps.linkLegals} element={<Legals />} />
      </Routes>
    </Router>
  );
}

export default App;
