import { Plane, Ring, Sphere, useTexture } from '@react-three/drei';
import { useRef } from 'react';
import { BackSide, Color, Mesh } from 'three';
import { useFrame } from '@react-three/fiber';

import VectorCircle from '../../assets/pictures/circlePlanet.png';
import BackgroundCircle from '../../assets/pictures/gridBackgroundWeb.png';
import MainPlanetRings from './MainPlanetRings';
import constProps from '../../config/const';

function MainPlanet(): JSX.Element {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const ref = useRef<Mesh>(null!);
  useFrame(() => {
    ref.current.rotateZ(-Math.PI / 16 / 100);
  });
  const texture = useTexture(VectorCircle);
  const backgroundTexture = useTexture(BackgroundCircle);

  return (
    <>
      <group>
        <mesh position={[0, 0, -40]} ref={ref}>
          <Plane args={[50, 27]}>
            <meshStandardMaterial attach="material" transparent map={backgroundTexture} />
          </Plane>
        </mesh>
        <mesh position={[0, 0, 0]} ref={ref}>
          <Ring args={[9, 9.5, 60]}>
            <meshStandardMaterial attach="material" transparent map={texture} />
          </Ring>
        </mesh>
        <mesh rotation-x={-Math.PI / 8} rotation-y={Math.PI / 8}>
          <group>
            <MainPlanetRings />
          </group>
        </mesh>
        <Sphere args={[8, 100, 300, 0, Math.PI * 2, Math.PI / 2, 3]} rotation={[1.5, 0, 0]} position={[0, 0, 0]}>
          <meshBasicMaterial color={new Color(constProps.transparentDark)} opacity={0.8} transparent side={BackSide} />
        </Sphere>
      </group>
    </>
  );
}

export default MainPlanet;
