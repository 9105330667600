import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import FooterStyled from './Footer.styled';
import Background from '../Background.styled';
import constProps from '../../config/const';
import { MyContext } from '../../page/Main';

type Props = {
  height: number;
};

function Footer({ height }: Props): JSX.Element {
  const { winWidth, winHeight } = React.useContext(MyContext);
  const { t } = useTranslation();

  return (
    <FooterStyled contentWidth={winWidth / 1.15} height={height} winHeight={winHeight} className="pageFragment">
      <div className="pageSample">
        <div id="footer-content">
          <p className="whiteText">{t('View.Footer.copyright')}</p>
          {/* eslint-disable-next-line prettier/prettier */}
          <pre id="footer-dash" className="whiteText"> - </pre>
          <Link id="footer-legalLink" className="whiteText" to={constProps.linkLegals}>
            {t('View.Footer.legalLink')}
          </Link>
        </div>
      </div>
      <Background id="background-footer" height={height} />
    </FooterStyled>
  );
}

export default Footer;
