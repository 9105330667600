import styled from 'styled-components';

import { isMenuOpenType } from '../..';

interface Props {
    open: isMenuOpenType;
}

export const StyledBurger = styled.button<Props>`
    --burger-size: ${({ theme }) => `${theme.headerHeightPercentRawMobile * 0.4}${theme.headerHeightMobileUnit}`};
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: var(--burger-size);
    height: var(--burger-size);
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    width: 7vw;
    height: 7vw;

    &:focus {
        outline: none;
    }

    div {
        width: 7vw;
        height: 0.4vh;
        background: ${({ theme }) => theme.primaryLight};
        border-radius: 10px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 0;

        :first-child {
            transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
        }

        :nth-child(2) {
            opacity: ${({ open }) => (open ? '0' : '1')};
            transform: ${({ open }) => (open ? 'translateX(20px)' : 'translateX(0)')};
        }

        :nth-child(3) {
            transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
        }
    }
`;
