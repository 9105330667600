import styled from 'styled-components';

interface Props {
    color?: string | undefined;
}

const StyledButton = styled.a<Props>`
    position: relative;
    background-color: ${({ color }) => color};
    display: flex;
    flex-direction: row;
    border-right: none;
    border-left: none;
    border-radius: 50px;
    box-shadow: 0px 0px 0px transparent;
    border: 0px solid transparent;
    text-shadow: 0px 0px 0px transparent;
    padding: 4.5vmin;
    align-items: center;
    height: fit-content;
    width: fit-content;
    cursor: pointer;
    text-decoration: none;

    .button-link {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: transparent;
    }

    #button-icon {
    }

    #button-padding {
        width: 3vmin;
    }

    #button-text {
        font-size: 6.5vmin;
    }

    @media only screen and (min-width: ${({ theme }) => theme.maxMobileRawWidth + 1}px) {
        padding-left: 1.3vw;
        padding-right: 1.3vw;
        padding-top: 0.5vw;
        padding-bottom: 0.5vw;
        cursor: pointer;

        :hover {
            box-shadow: 1px 1px 2px ${({ color }) => `${color}99`};
        }

        #button-padding {
            width: 0.8vw;
        }

        #button-text {
            font-size: 1.2vw;
        }
    }
`;

export default StyledButton;
