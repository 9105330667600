import styled from 'styled-components';

interface Props {
    contentWidth: number;
    height: number;
    winHeight: number;
}

const StyledFooter = styled.div<Props>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .pageSample {
        margin-top: 2vh;
    }

    #footer-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 90vw;
        border-top: solid ${({ theme }) => `${theme.primaryLight}40`};
        border-width: 1px;
        padding-top: 3vmin;
        padding-bottom: 3vmin;
    }

    #footer-content p,
    #footer-dash,
    #footer-legalLink {
        font-size: 3vmin;
        line-height: 30%;
    }

    #footer-legalLink {
        text-decoration: none;
        font-family: 'Ubuntu';
        margin-block-start: 0px;
        margin-block-end: 0px;
        font-weight: 100;
    }

    #background-footer {
        background-color: ${({ theme }) => theme.fullDark};
        height: 100%;
    }

    @media only screen and (min-width: ${({ theme }) => theme.maxMobileRawWidth + 1}px) {
        .pageSample {
            margin: 0;
        }

        #footer-content p,
        #footer-dash,
        #footer-legalLink {
            font-size: 1.2vh;
            line-height: 100%;
        }

        #footer-content {
            flex-direction: row;
            padding-top: 0.8vh;
            padding-bottom: 0.8vh;
            width: ${({ theme }) => theme.webContentWidth};
        }

        #footer-legalLink:hover {
            text-decoration: underline;
        }

        #background-footer {
            height: 100%;
        }
    }
`;

export default StyledFooter;
