import styled from 'styled-components';

const StyledFormation = styled.div`
    #formation-media-content {
        margin-top: ${({ theme }) =>
            `${theme.pageMarginBetweenContentsPercentRawMobile}${theme.pageMarginBetweenContentsMobileUnit}`};
    }

    #formation-youtube-container {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        width: 100%;
    }

    #formation-youtube-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }

    #background-formation {
        background-color: ${({ theme }) => theme.primaryGrey};
    }

    @media only screen and (min-width: ${({ theme }) => theme.maxMobileRawWidth + 1}px) {
        #formation-sample-content {
            height: 25%;
            flex-direction: row-reverse;
        }

        #formation-sample-content .text-container {
            width: 60%;
        }

        #formation-sample-content .title1-container {
            width: 35%;
        }

        #formation-media-content {
            height: 50%;
            align-items: center;
            margin-top: ${({ theme }) =>
                `${theme.pageMarginBetweenContentsPercentRawWeb + 4}${theme.pageMarginBetweenContentsWebUnit}`};
        }

        #formation-media-content-text {
            display: flex;
            flex-direction: column;
            width: 50%;
            justify-content: space-between;
        }

        .title1-container {
            margin-bottom: 0;
        }

        #formation-media-content-text .title1-container {
            width: 100%;
        }

        #formation-media-content-text .text-container {
            width: 100%;
            justify-content: center;
        }

        #formation-media-video-container {
            width: 47%;
        }
    }
`;

export default StyledFormation;
