// Warning
/* eslint-disable react/no-danger */
import { Html } from '@react-three/drei';
import { useTranslation } from 'react-i18next';
import StyledPopUp from './PopUp.Styled';

type Props = {
  color: string;
  text: string;
};
export default function PopUp({ color, text }: Props): JSX.Element {
  const { t } = useTranslation();

  return (
    <mesh position={[0.5, 3, 0]}>
      <Html center>
        <StyledPopUp color={color}>
          <div className="container">
            <div className="i18n" data-i18n="[html]content.body">
              <p className="whiteText" dangerouslySetInnerHTML={{ __html: t(`View.Popup.${text}`) }} />
            </div>
          </div>
        </StyledPopUp>
      </Html>
    </mesh>
  );
}
