import React from 'react';
import { useTranslation } from 'react-i18next';
import { SvgLoader } from 'react-svgmt';

import constProps from '../../config/const';
import youtube from '../../config/youtube.json';
import info from '../../config/doNow-info.json';

import Button, { ActionType as ButtonActionType } from '../../component/Button';

import StyledFormation from './Formation.styled';
import Background from '../../component/Background.styled';

import planetBack1 from '../../assets/pictures/planetBack1.svg';
import planetBack2 from '../../assets/pictures/planetBack2.svg';
import { MyContext } from '../Main';

type Props = {
  pageHeight: number;
};

function Formation({ pageHeight }: Props | any): JSX.Element {
  const { isMobile } = React.useContext(MyContext);
  const { t } = useTranslation();

  const printContactButton = (color: string, media = false): JSX.Element => (
    <Button color={color} actionType={ButtonActionType.MAIL}>
      {t(`View.Formation.${media ? 'media' : 'formation'}.buttonText`)}
    </Button>
  );

  const displayYoutubeVideo = (): JSX.Element => (
    <div id="formation-youtube-container">
      <iframe
        width="100%"
        src={youtube.link}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );

  const displayMediaContent = (): JSX.Element => {
    const contentClasses = 'sample-content';
    const contentId = 'formation-media-content';

    const TitleContainer = (): JSX.Element => (
      <div className="title1-container">
        <h1 className="capitalize title1">{t('View.Formation.media.title')}</h1>
        <h2 className="capitalize blueText subTitle1">{t('View.Formation.media.subTitle')}</h2>
      </div>
    );

    const TextContainer = (): JSX.Element => (
      <div className="text-container">
        <div className="br">
          <br />
        </div>
        <p className="text">{t('View.Formation.media.p1')}</p>
        <div className="br">
          <br />
        </div>
        <p className="text">{t('View.Formation.media.p2')}</p>
        <div className="br">
          <br />
        </div>
        <p className="text">{t('View.Formation.media.p3')}</p>
        <div className="br">
          <br />
        </div>
        <p className="text bolder">{t('View.Formation.media.p4')}</p>
        <div className="br">
          <br />
        </div>
      </div>
    );
    const ytLinkButton = (
      <Button color={constProps.primaryBlue} actionType={ButtonActionType.EXT_LINK} actionPayload={info.youtubeLink}>
        {t('View.Formation.media.buttonText')}
      </Button>
    );

    return isMobile ? (
      <div id={contentId} className={contentClasses}>
        <TitleContainer />
        {displayYoutubeVideo()}
        <TextContainer />
        {ytLinkButton}
      </div>
    ) : (
      <div id={contentId} className={contentClasses}>
        <div id="formation-media-content-text">
          <TitleContainer />
          <TextContainer />
          {ytLinkButton}
        </div>
        <div id="formation-media-video-container">{displayYoutubeVideo()}</div>
      </div>
    );
  };

  const floatingPlanetStyle = {
    position: 'absolute',
    zIndex: constProps.zIndexPage - 5
  };

  return (
    <StyledFormation className="pageFragment">
      <div className="pageSample">
        <div id="formation-sample-content" className="sample-content">
          <div className="title1-container">
            <div>
              <h1 className="capitalize title1">{t('View.Formation.formation.title')}</h1>
              <h2 className="capitalize orangeText subTitle1">{t('View.Formation.formation.subTitle')}</h2>
            </div>
            {!isMobile && printContactButton(constProps.primaryOrange)}
          </div>
          <div className="text-container">
            <p className="text">{t('View.Formation.formation.p1')}</p>
            <div className="br">
              <br />
            </div>
            <p className="text">{t('View.Formation.formation.p2')}</p>
            <div className="br">
              <br />
            </div>
            <p className="text">{t('View.Formation.formation.p3')}</p>
            <div className="br">
              <br />
            </div>
            <p className="text">{t('View.Formation.formation.p4')}</p>
            <div className="br">
              <br />
            </div>
            <p className="text bolder">{t('View.Formation.formation.p5')}</p>
          </div>
          {isMobile && printContactButton(constProps.primaryOrange)}
        </div>
        {displayMediaContent()}
      </div>
      {!isMobile && (
        <>
          <SvgLoader
            src={planetBack1}
            alt="planetBack1"
            style={{ ...floatingPlanetStyle, right: '-25%', top: '0%', width: '45vw' }}
          />
          <SvgLoader
            src={planetBack2}
            alt="planetBack2"
            style={{ ...floatingPlanetStyle, left: '-14%', top: '50%', width: '19vw' }}
          />
        </>
      )}
      <Background height={pageHeight} id="background-formation" />
    </StyledFormation>
  );
}

export default Formation;
