// realized with help of https://css-tricks.com/hamburger-menu-with-a-side-of-react-hooks-and-styled-components/

import { useEffect, useState } from 'react';
import { Link } from 'react-scroll';

import { handleScrollMoveType, resetHandleScrollMoveType, isOnTopType, pagesType, infoType } from '..';

import StyledHeader from '../Header.styled';
import Burger from './Elements/Burger';
import Menu from './Elements/Menu';

import logo from '../../../assets/pictures/donow-1920.png';

export type isMenuOpenType = boolean;

export type setIsMenuOpenType = (open: boolean) => void;

type Props = {
  handleScrollMove: handleScrollMoveType;
  resetHandleScrollMove: resetHandleScrollMoveType;
  isOnTop: isOnTopType;
  pages: pagesType;
  info: infoType;
};

function Header({ handleScrollMove, resetHandleScrollMove, isOnTop, pages, info }: Props): JSX.Element {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (handleScrollMove) {
      setIsMenuOpen(false);
      resetHandleScrollMove();
    }
  }, [handleScrollMove, resetHandleScrollMove]);

  return (
    <StyledHeader isOnTop={isOnTop} isMenuOpen={isMenuOpen}>
      <Menu isOpen={isMenuOpen} setIsOpen={setIsMenuOpen} pages={pages} info={info} />
      <div />
      <Link smooth to={pages[0].name}>
        <img className="header-logo" src={logo} alt="DoNow logo" />
      </Link>
      <Burger open={isMenuOpen} setOpen={setIsMenuOpen} />
    </StyledHeader>
  );
}

export default Header;
