import { useMemo } from 'react';
import _ from 'lodash';

import MobileCarousel from './mobile';
import WebCarousel from './web';
import constProps from '../../config/const';

import StyledLogoCarousel from './LogoCarousel.styled';

type OwnProps = {
  isMobile: boolean;
  heightWeb: string;
  heightMobile: string;
  backgroundColor: string;
  logosMobile: { [key: string]: string };
  logosWeb: { [key: string]: string };
  screenWidth: number;
};

function LogoCarousel({
  isMobile,
  heightWeb,
  heightMobile,
  screenWidth,
  backgroundColor,
  logosMobile,
  logosWeb
}: OwnProps): JSX.Element {
  const [logosWebColor, logosWebGrey]: [{ [key: string]: string }, { [key: string]: string }] = useMemo(
    () =>
      isMobile
        ? [[], []]
        : _.reduce(
            logosWeb,
            (acc, val, key) =>
              _.endsWith(key, constProps.greyExtension)
                ? [acc[0], { ...acc[1], [`${key.substring(0, key.length - constProps.greyExtension.length)}`]: val }]
                : [{ ...acc[0], [key]: val }, acc[1]],
            [{}, {}]
          ),
    [logosWeb, isMobile]
  );

  return (
    <StyledLogoCarousel
      height={isMobile ? heightMobile : heightWeb}
      backgroundColor={backgroundColor}
      screenWidth={screenWidth}
    >
      {isMobile ? (
        <MobileCarousel logos={logosMobile} />
      ) : (
        (() => {
          const webLogos = Object.keys(logosWebColor)
            .slice(
              0,
              Object.keys(logosWebColor).length > constProps.logoCarouselNumberLogosDisplayedWeb
                ? constProps.logoCarouselNumberLogosDisplayedWeb
                : Object.keys(logosWebColor).length
            )
            .reduce((a: { [key: string]: string }, key: string) => ({ ...a, [key]: logosWebColor[key] }), {});
          return <WebCarousel logos={webLogos} logosGrey={logosWebGrey} />;
        })()
      )}
    </StyledLogoCarousel>
  );
}

export default LogoCarousel;
