import styled from 'styled-components';

const StyledLegals = styled.div`
    background-color: rgb(21, 21, 21);
    color: white;
    font-family: 'Ubuntu';
    padding: 50px;

    a {
        color: #00bce8;
        text-decoration: none;
    }

    a:hover {
        text-decoration: none;
        color: White;
    }
`;

export default StyledLegals;
