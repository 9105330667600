import { Canvas } from '@react-three/fiber';

import { Suspense } from 'react';
import MainPlanet from './MainPlanet';
import planetData from './PlanetData';
import PlanetOnElipse from './PlanetOnElipse';
import constProps from '../../config/const';
import Lights from './Lights';

function DonowPlanet(): JSX.Element {
  return (
    <Canvas orthographic camera={{ zoom: 35, position: [0, 0, 25], fov: 60 }} dpr={[1, 1.5]}>
      <color attach="background" args={[constProps.fullDark]} />
      <Suspense fallback={null}>
        <MainPlanet />
      </Suspense>
      {planetData.map((planet) => (
        <PlanetOnElipse planet={planet} key={planet.id} />
      ))}
      <Lights />
    </Canvas>
  );
}

export default DonowPlanet;
