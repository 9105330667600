import _ from 'lodash';

import activBold from './activBold.png';
import activBoldGrey from './activBoldGrey.png';
import aleysia from './aleysia.png';
import aleysiaGrey from './aleysiaGrey.png';
import carrefour from './carrefour.png';
import carrefourGrey from './carrefourGrey.png';
import iOMentum from './iOMentum.png';
import iOMentumGrey from './iOMentumGrey.png';
import majengo from './majengo.png';
import majengoGrey from './majengoGrey.png';
import middleway from './middleway.png';
import middlewayGrey from './middlewayGrey.png';
import nestor from './nestor.png';
import nestorGrey from './nestorGrey.png';
import seb from './seb.png';
import sebGrey from './sebGrey.png';
import stormshield from './stormshield.png';
import stormshieldGrey from './stormshieldGrey.png';
import vivlio from './vivlio.png';
import vivlioGrey from './vivlioGrey.png';
import { shuffleObjectProperties } from '../../../utils/utils';

const logos: { [key: string]: { [key: string]: string } } = {
    png: {
        activBold,
        activBoldGrey,
        aleysia,
        aleysiaGrey,
        carrefour,
        carrefourGrey,
        iOmentum: iOMentum,
        iOmentumGrey: iOMentumGrey,
        majengo,
        majengoGrey,
        middleway,
        middlewayGrey,
        nestor,
        nestorGrey,
        seb,
        sebGrey,
        stormshield,
        stormshieldGrey,
        vivlio,
        vivlioGrey
    },
    svg: {
        activBold,
        aleysia,
        carrefour,
        iOMentum,
        majengo,
        middleway,
        nestor,
        seb,
        vivlio
    }
};

export default _.reduce(
    logos,
    (acc, val, key) => ({ ...acc, ..._.reduce(val, (a, v, k) => ({ ...a, [`${k}${key.toUpperCase()}`]: v }), {}) }),
    {}
);

export const svgLogos = shuffleObjectProperties(logos.svg);
export const pngLogos = shuffleObjectProperties(logos.png);
