import styled from 'styled-components';

interface Props {
    isMobile: boolean;
}

const StyledMain = styled.main<Props>`
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    z-index: ${({ theme }) => theme.zIndexPage};

    a {
        color: ${({ theme }) => theme.primaryBlue};
        text-decoration: none;
    }

    a:visited {
        color: ${({ theme }) => theme.primaryOrange};
    }

    p,
    span,
    h1,
    h2,
    h3,
    h4,
    br {
        font-family: 'Ubuntu';
        margin-block-start: 0px;
        margin-block-end: 0px;
        font-weight: 100;
    }

    p {
        line-height: 140%;
    }

    .pageLink {
        height: 0px;
        width: 0px;
    }

    .pageFragment {
        height: 100%;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;
    }

    .pageSample {
        margin-top: ${({ theme }) => theme.pageMarginBetweenContentsMobile};
        width: ${({ theme }) => theme.mobileWidthPercent};
        height: 100%;
        z-index: ${({ theme }) => theme.zIndexPage};
        flex-direction: column;
        display: flex;
        align-items: center;
    }

    .capitalize {
        text-transform: uppercase;
    }

    .bold {
        font-weight: bold;
    }

    .lighter {
        font-weight: lighter;
    }

    .bolder {
        font-weight: bolder;
    }

    .whiteText {
        color: ${({ theme }) => theme.primaryLight};
    }

    .darkerGreyText {
        color: ${({ theme }) => theme.darkerGrey};
    }

    .blueText {
        color: ${({ theme }) => theme.primaryBlue};
    }

    .orangeText {
        color: ${({ theme }) => theme.primaryOrange};
    }

    .title1 {
        font-size: 10vw;
        font-weight: bolder;
    }

    .subTitle1 {
        font-size: 6vw;
        font-weight: lighter;
        letter-spacing: 1vw;
    }

    .title1-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 2.5vh;
    }

    .title1-container .title1 {
        margin-bottom: 1.2vh;
    }

    .title2 {
        font-size: 7vmin;
        font-weight: bold;
        margin-bottom: 0.4vmin;
    }

    .subTitle2 {
        font-size: 3vmin;
        font-weight: lighter;
        letter-spacing: 0.25vmin;
        margin-bottom: 0.7vmin;
    }

    .text-container {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        margin-bottom: 3vh;
    }

    --text-size: 3.8vmin;
    .text-container .text {
        font-size: var(--text-size);
    }

    .text-container .br,
    .br {
        height: var(--text-size);
    }

    .sample-content {
        display: flex;
        flex-direction: column;
        z-index: ${({ theme }) => theme.zIndexPage + 1};
        align-items: center;
        justify-content: space-around;
    }

    @media only screen and (min-width: ${({ theme }) => theme.maxMobileRawWidth + 1}px) {
        .pageSample {
            width: 100%;
            ${({ theme }) =>
                `margin-top: ${theme.pageMarginTopWeb};
            margin-bottom: ${theme.pageMarginBottomWeb};`}
            justify-content: space-between;
        }

        .title1 {
            font-size: 3.2vw;
            margin-block-end: 0.25vw;
        }

        .subTitle1 {
            font-size: 1.3vw;
            letter-spacing: 0.25vw;
        }

        .title1-container {
            align-items: none;
            justify-content: flex-start;
        }

        .title1-container .title1 {
            margin-bottom: 0.5vw;
        }

        .title1-container .subTitle1 {
            margin-bottom: 1.5vw;
        }

        .title2 {
            font-size: 2.3vw;
        }

        .subTitle2 {
            font-size: 0.87vw;
        }

        --text-size: 0.82vw;
        .text-container .text,
        .text {
            font-size: var(--text-size);
        }

        .text-container .br,
        .br {
            height: var(--text-size);
        }

        .sample-content {
            flex-direction: row;
            justify-content: space-between;
            width: ${({ theme }) => theme.webContentWidth};
            align-items: stretch;
        }

        .sample-content .title1-container {
            width: 25%;
        }

        .sample-content .text-container {
            width: 65%;
        }

        #home-youtubeNode {
            height: 1.3vw;
            width: 13vw;
            background-color: ${({ theme }) => theme.primaryLight};
            position: fixed;
            display: flex;
            right: 0;
            top: ${({ theme }) => theme.headerHeightWeb};
            margin-top: 17vh;
            z-index: 35;
            justify-content: flex-start;
            align-items: center;
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
            padding: 0.8vw;
            padding-left: 0.45vw;
            transform: translateX(75%);
            --color-filter: invert(0%);
            transition: transform 0.45s ease-in-out;
        }

        #home-youtubeNode:hover {
            transform: translateX(0%);
            --color-filter: invert(40%) sepia(99%) saturate(880%) hue-rotate(360deg) brightness(97%) contrast(102%);
        }

        /* filter generated with: https://codepen.io/sosuke/pen/Pjoqqp */
        #home-youtubeLogo {
            width: 5vw;
            filter: var(--color-filter);
        }

        #home-youtubeText {
            color: #f26d00;
            font-size: 0.7vw;
            margin-left: 0.9vw;
        }
    }
`;

export default StyledMain;
