/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Sphere, Line } from '@react-three/drei';
import { useFrame } from '@react-three/fiber';
import { useRef, useState, useMemo } from 'react';
import { Mesh } from 'three';
import PopUp from '../PopUp';

type Props = {
  color: string;
  xRadius: number;
  zRadius: number;
  size: number;
  hasPopup: boolean;
  text: string | undefined;
  action: string;
};

function Planet({ color, xRadius, zRadius, size, hasPopup, text, action }: Props): JSX.Element {
  const ref = useRef<Mesh>(null!);
  const [state, setState] = useState({ action: 'none', theta: 0 });
  const component = useMemo(
    () => (
      <mesh>
        <Line
          rotation={[0, 0, -0.2]}
          points={[
            [0, 0, 0],
            [0, 3, 0]
          ]}
          color="#FFFFFF"
          lineWidth={0.3}
          alphaWrite
          dashSize={0.01}
          gapSize={0.1}
        />
        <PopUp color={color} text={text!} />
      </mesh>
    ),
    [color, text]
  );

  const dTheta = (2 * Math.PI) / 1000;

  useFrame(() => {
    if (action === 'none' && state.action === 'none') {
      state.theta += dTheta;
      const x = xRadius * Math.cos(state.theta * size);
      const z = zRadius * Math.sin(state.theta * size);
      ref.current.position.x = x;
      ref.current.position.z = z;
    }
  });
  return (
    <mesh
      position={[xRadius, 0, 0]}
      ref={ref}
      onPointerLeave={() =>
        setState((prevState) => {
          return { ...prevState, action: 'none' };
        })
      }
      onPointerOver={() =>
        setState((prevState) => {
          return { ...prevState, action: 'over' };
        })
      }
      onPointerDown={() =>
        setState((prevState) => {
          return { ...prevState, action: 'click' };
        })
      }
      onPointerUp={() =>
        setState((prevState) => {
          return { ...prevState, action: 'none' };
        })
      }
    >
      {(state.action === 'click' || action !== 'none') && hasPopup && component}

      <sphereGeometry args={[size, 32, 32]} />
      <meshStandardMaterial color={color} />
      {(state.action === 'over' || state.action === 'click' || action !== 'none') && (
        <Sphere args={[size + 0.2, 32, 64]}>
          <meshStandardMaterial transparent opacity={0.1} />
        </Sphere>
      )}
    </mesh>
  );
}

export default Planet;
