import constProps from '../../config/const';

type dataPlanet = {
  id: number;
  color: string[];
  xRadius: number;
  zRadius: number;
  size: number;
  hasPopup: boolean;
  text: string[] | undefined[];
};

const colorPair = [
  [constProps.primaryLight, constProps.primaryLight],
  [constProps.primaryLight, constProps.primaryLight],
  [constProps.primaryBlue, constProps.primaryOrange]
];
const sizes = [0.2, 0.25, 0.5];
const popup = [false, false, true];
const planetData: dataPlanet[] = [];

const ellipseRadiuses = [
  { x: 2 * 6, z: 2.5 * 4 },
  { x: 2.5 * 6, z: 3.5 * 4 },
  { x: 3 * 6, z: 4 * 4.5 }
];

const text = [
  [undefined, undefined],
  [undefined, undefined],
  ['blue', 'orange']
];

const totalPlanets = 3;

for (let index = 0; index < totalPlanets; index += 1) {
  planetData.push({
    id: index,
    color: colorPair[index],
    xRadius: ellipseRadiuses[index].x,
    zRadius: ellipseRadiuses[index].z,
    size: sizes[index],
    hasPopup: popup[index],
    text: text[index]
  });
}

export default planetData;
