import styled from 'styled-components';

const StyledHome = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    .background-home {
        background-color: ${({ theme }) => theme.primaryDark};
    }

    #home-planet {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        height: 60vmin;
    }

    .home-planet-element {
        position: absolute;
        z-index: ${({ theme }) => theme.zIndexPage + 1};
        opacity: 1;
        width: 100vmin;
    }

    #home-planet-svg {
    }

    #home-planet-roundSvg {
        z-index: ${({ theme }) => theme.zIndexPage + 2};
    }

    #home-title {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .home-mainText {
            padding: 7px;
            border-radius: 5px;
            width: fit-content;
            // Warning: backdrop-filter doesn't work on mozilla
            backdrop-filter: blur(4px);
            -webkit-backdrop-filter: blur(4px);
            z-index: ${({ theme }) => theme.zIndexPage + 3};
            background: #202020ba;
            font-size: 6vmin;
            text-align: center;
            font-weight: bolder;
        }
    }

    .home-arrowNextPage,
    .home-arrowNextPage-move {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 0;
        padding-bottom: 4.5vh;
        height: 4.8vh;
        width: 4.8vh;
        z-index: ${({ theme }) => theme.zIndexPage + 4};
        cursor: pointer;
        transform: translateY(0%);
        transition: transform 0.7s ease;
    }

    .home-arrowNextPage-move {
        transform: translateY(20%);
    }

    @media only screen and (min-width: ${({ theme }) => theme.maxMobileRawWidth + 1}px) {
        .home-planet-element {
            width: 75vw;
            max-width: 90vh;
        }

        #home-planet-svg {
            transform: scale(1.4);
        }

        #home-title {
            width: 85%;
            height: 55%;
            .home-mainText {
                font-size: 5vh;
            }
        }

        #home-planet {
            height: 100%;
        }
        #home-planetRingsSvg {
        }

        .home-arrowNextPage,
        .home-arrowNextPage-move {
            padding-bottom: 4vh;
        }

        .home-arrowNextPage:hover {
            transform: translateY(20%);
            transition: transform 0.7s ease;
        }
    }
`;

export default StyledHome;
