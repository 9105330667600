import styled from 'styled-components';

interface Props {
    pageHeight?: number;
}

const StyledPage = styled.div<Props>`
    ${({ pageHeight }) => pageHeight !== null && pageHeight !== undefined && `height: ${pageHeight}px;`};
    width: 100%;
    z-index: ${({ theme }) => theme.zIndexPage};
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
`;

export default StyledPage;
