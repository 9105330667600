import React from 'react';
import { useTranslation } from 'react-i18next';
import { SvgLoader } from 'react-svgmt';

import Map from '../../component/Map';
import PointGrid from '../../component/PointGridLayer';
import Background from '../../component/Background.styled';
import LogoCarouselPartners from '../../component/LogoCarousel/LogoCarouselPartners';
import StyledContact from './Contact.styled';
import logo from '../../assets/pictures/donow-1920.png';
import Button, { ActionType as ButtonActionType } from '../../component/Button';
import constProps from '../../config/const';
import icons from '../../assets/icons';
import mapPinDonow from '../../assets/pictures/mapPinDonow.svg';
import mapPinVaiseStationEN from '../../assets/pictures/mapPinVaiseEN.svg';
import mapPinVaiseStationFR from '../../assets/pictures/mapPinVaiseFR.svg';
import info from '../../config/doNow-info.json';
import { MyContext } from '../Main';

type Props = {
  pageHeight: number;
  footerLink: JSX.Element;
};

function Contact({ pageHeight, footerLink }: Props | any): JSX.Element {
  const { isMobile, vminInPx, winWidth, headerHeightPx, winHeight } = React.useContext(MyContext);
  const { t, i18n } = useTranslation();

  const logoStyle = isMobile ? { height: '5vw', width: '5vw' } : { height: '1.05vw', width: '1.05vw' };
  const position: [number, number] = [45.77894973754883, 4.805688858032227]; // 13 rue Masaryk, Lyon
  const positionVaise: [number, number] = [45.780383, 4.80441]; // Gare de Vaise

  return (
    <StyledContact className="pageFragment" vminInPx={vminInPx}>
      <div className="pageSample">
        <div id="contact-partners">
          <div id="contact-partner-title">
            <h1 className="capitalize darkerGreyText title2">{t('View.Contact.partners.title')}</h1>
            <h2 className="capitalize darkerGreyText subTitle2">{t('View.Contact.partners.subTitle')}</h2>
          </div>
          <LogoCarouselPartners isMobile={isMobile} heightWeb="19vh" heightMobile="15vh" screenWidth={winWidth} />
        </div>
        {footerLink}
        <div id="contact-contact">
          <div id="contact-contact-title" className={isMobile ? 'title1-container' : undefined}>
            <h1 className="title1 whiteText capitalize">{t('View.Contact.contact.title')}</h1>
            <h2 className="subTitle1 orangeText capitalize">{t('View.Contact.contact.subTitle')}</h2>
          </div>
          <div id="contact-contact-content" className="sample-content">
            <div id="contact-contact-content-text" className="text-container">
              {!isMobile && <img id="contact-contact-content-text-logo" src={logo} alt="DoNow logo" />}
              <div className="br">
                <br />
              </div>
              <p className="whiteText text">{t('View.Contact.contact.text')}</p>
              <div className="br">
                <br />
              </div>
              <Button color={constProps.primaryOrange} actionType={ButtonActionType.MAIL}>
                {t('View.Contact.contact.button')}
              </Button>
            </div>
            <div id="contact-contact-content-map">
              <Map
                center={[position[0] - winHeight * 0.000001, position[1]]}
                pins={[
                  {
                    position,
                    icon: mapPinDonow,
                    iconSize: [70, 70],
                    onPress: () => window.open(info.addressLink, '_blank')?.focus()
                  },
                  {
                    position: positionVaise,
                    icon: i18n.language === 'fr' ? mapPinVaiseStationFR : mapPinVaiseStationEN,
                    iconSize: [70, 70],
                    displayConditions: { minZoom: 15 }
                  }
                ]}
                zoom={16}
                maxZoom={18}
                id="contact-map"
              />
            </div>
            <div id="contact-contact-content-info">
              <h3 className="whiteText capitalize bolder">{t('View.Contact.contact.contactLabel')}</h3>
              <div className="br">
                <br />
              </div>
              <div className="contact-contact-content-info-content">
                <div className="contact-contact-content-info-title">
                  <SvgLoader style={logoStyle} path={icons.addressWhite} />
                  &ensp;
                  <h4 className="whiteText bolder">{t('View.Contact.contact.addressLabel')}</h4>
                </div>
                <a
                  className="text whiteText"
                  id="contact-addressLink"
                  href={info.addressLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {info.address}
                </a>
              </div>
              <div className="br">
                <br />
              </div>
              <div className="contact-contact-content-info-content">
                <div className="contact-contact-content-info-title">
                  <SvgLoader style={logoStyle} path={icons.tel} />
                  &ensp;
                  <h4 className="whiteText bolder">{t('View.Contact.contact.telLabel')}</h4>
                </div>
                <p className="text whiteText">{info.tel}</p>
              </div>
              <div className="br">
                <br />
              </div>
              <div className="contact-contact-content-info-content">
                <div className="contact-contact-content-info-title">
                  <SvgLoader style={logoStyle} path={icons.mail} />
                  &ensp;
                  <h4 className="whiteText bolder">{t('View.Contact.contact.emailLabel')}</h4>
                </div>
                <Button actionType={ButtonActionType.MAIL} hideButton>
                  <p className="text whiteText">{info.mail}</p>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Background height={pageHeight} id="background-contact">
        <div id="background-contact-top" />
        <div id="background-contact-bottom">
          {!isMobile && (
            <PointGrid
              pageHeight={pageHeight * 0.8 - headerHeightPx}
              marginTop={pageHeight * 0.12}
              backgroundColor={constProps.fullDark}
            />
          )}
        </div>
        {isMobile && <div id="background-contact-bottom-sub" />}
      </Background>
    </StyledContact>
  );
}

export default Contact;
