import React from 'react';
import styled from 'styled-components';

import { MyContext } from '../page/Main';

interface StyleProps {
  isMobile: boolean;
  pageHeight: number;
  width: number;
  backgroundColor: string;
  marginTop: number;
}

const squareSize = (isMobile: boolean, width: number): number => (isMobile ? width / 9 : width / 23);
const squarePointSize = (isMobile: boolean, width: number): number => squareSize(isMobile, width);

const StyledGrid = styled.div<StyleProps>`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zIndexGrid};
  display: fixed;
  margin-top: ${({ marginTop }) => marginTop}px;

  .points,
  .lines {
    background-image: ${({ backgroundColor, isMobile }) =>
      `radial-gradient(white 0%, ${backgroundColor} ${isMobile ? 60 : 55}%)`};
    position: absolute;
    background-position: center;
  }

  .points {
    top: ${({ isMobile, width }) => `${squarePointSize(isMobile, width) / 2 + 0.15}`}px;
    bottom: ${({ isMobile, width }) => `${squarePointSize(isMobile, width) / 2}`}px;
    right: ${({ isMobile, width }) => `${squarePointSize(isMobile, width) / 2}`}px;
    left: ${({ isMobile, width }) => `${squarePointSize(isMobile, width) / 2 + 0.15}`}px;
    mask-size: ${({ isMobile, width }) =>
      `${squarePointSize(isMobile, width)}px ${squarePointSize(isMobile, width)}px`};
    mask-image: ${({ theme }) => `radial-gradient(${theme.gridGrey}80 2px, transparent 0)`};
  }

  .lines {
    height: 100%;
    width: 100%;
    mask-size: ${({ isMobile, width }) => `${squareSize(isMobile, width)}px ${squareSize(isMobile, width)}px`};
    mask-image: ${({ theme }) =>
      `linear-gradient(to right, ${theme.gridGrey}70 0.1px, transparent 1px),
        linear-gradient(to bottom, ${theme.gridGrey}70 0.1px, transparent 1px)`};
  }
`;

type Props = {
  pageHeight: number;
  backgroundColor: string;
  marginTop?: number;
};

const defaultMarginTop = 0;

const PointGridLayer = ({ pageHeight, backgroundColor, marginTop = defaultMarginTop }: Props): JSX.Element => {
  const { isMobile, winWidth } = React.useContext(MyContext);

  return (
    <StyledGrid
      pageHeight={pageHeight}
      width={winWidth}
      isMobile={isMobile}
      backgroundColor={backgroundColor}
      marginTop={marginTop}
    >
      <div className="points" />
      <div className="lines" />
    </StyledGrid>
  );
};

PointGridLayer.defaultProps = { marginTop: defaultMarginTop };

export default PointGridLayer;
