import styled from 'styled-components';

type Props = {
    size: number;
};

const StyledCard = styled.div<Props>`
    display: inline-block;
    position: relative;
    width: ${({ size }) => size}vw;

    .card-sizing {
        margin-top: 100%;
    }

    .card-content {
        background-color: ${({ theme }) => theme.primaryLight};
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        display: flex;
        border-radius: 5%;
        margin: 1vmax;
        padding: 1.5vmax;
        padding-top: 2vmax;
    }

    .card-picture-container {
        height: 50%;
        align-items: center;
        justify-content: center;
    }

    .card-picture {
        height: 70%;
    }

    .card-bottom {
        height: 50%;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
        display: flex;
        padding-bottom: 10%;
    }

    .card-title {
        font-size: ${({ size }) => 0.073 * size}vmin;
        font-weight: bolder;
    }

    .card-text {
        font-size: ${({ size }) => 0.045 * size}vmin;
        text-align: center;
    }

    @media only screen and (min-width: ${({ theme }) => theme.maxMobileRawWidth + 1}px) {
        width: ${({ size }) => size}vw;

        .card-content {
            box-shadow: 1px 1px 0px ${({ theme }) => theme.gridGrey};
            margin: 0.6vw;
            padding: 0.85vw;
            padding-top: 1.2vw;
        }

        .card-title {
            font-size: ${({ size }) => 0.073 * size}vw;
        }

        .card-text {
            font-size: ${({ size }) => 0.045 * size}vw;
        }
    }
`;

export default StyledCard;
