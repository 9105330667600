import styled from 'styled-components';

const backgroundBottomMargin = 40;

interface Props {
    vminInPx: number;
}

const StyledContact = styled.div<Props>`
    #contact-partners {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }

    #contact-partner-title {
        margin-bottom: 3vmin;
    }

    #contact-contact {
        margin-top: ${({ theme }) => theme.pageMarginBetweenContentsMobile};
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    #contact-contact-title {
    }

    #contact-contact-content-text,
    #contact-contact-content-map,
    #contact-contact-content-info {
    }

    #contact-contact .title1,
    #contact-contact .subTitle1,
    .title2,
    .subTitle2 {
        text-align: center;
    }

    #contact-contact-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }

    #contact-contact-content-text {
        display: flex;
        align-items: center;
    }

    #contact-contact-content-map {
        margin-bottom: 3vh;
    }

    #contact-map {
        width: ${({ theme }) => theme.mobileWidthPercentRaw}vw;
        height: ${({ theme }) => theme.mobileWidthPercentRaw}vw;
        border-radius: 8px;
    }

    #contact-contact-content-info {
        align-self: flex-start;
    }

    #contact-contact-content-text-logo {
        align-self: flex-start;
        height: 10vh;
        width: 48vw;
    }

    .contact-contact-content-info-title {
        display: flex;
        flex-direction: row;
    }

    #contact-contact-content-text,
    #contact-contact-content-info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    #contact-addressLink {
        text-decoration: none;
    }

    h3 {
        font-size: 6vw;
    }

    h4 {
        font-size: 5vw;
    }

    #background-contact-top {
        display: absolute;
        background-color: ${({ theme }) => theme.primaryGrey};
        z-index: ${({ theme }) => theme.zIndexBackground + 2};
        height: 15%;
        width: 100%;
        border-bottom: none;
    }

    #background-contact-bottom,
    #background-contact-bottom-sub {
        position: absolute;
        background-color: ${({ theme }) => theme.fullDark};
        bottom: 0;
    }

    #background-contact-bottom {
        z-index: ${({ theme }) => theme.zIndexBackground + 1};
        height: 85%;
        width: 100%;
        border-top: none;
        overflow: hidden;
    }

    #background-contact-bottom-sub {
        z-index: ${({ theme }) => theme.zIndexBackground};
        height: ${({ vminInPx }) => backgroundBottomMargin * vminInPx + 1}px;
        width: 100%;
        display: flex;
        margin-bottom: 0;
    }

    @media only screen and (min-width: ${({ theme }) => theme.maxMobileRawWidth + 1}px) {
        #contact-partners {
        }

        .title2 {
            margin-top: 1%;
        }

        .subTitle2 {
            margin-bottom: 2%;
        }

        #contact-contact {
            height: 58%;
            width: 70vw;
            margin-top: ${({ theme }) => theme.pageMarginBetweenContentsWeb};
            margin-bottom: 0;
            align-items: center;
        }

        #contact-contact-title {
            margin-bottom: 0;
            backdrop-filter: blur(20px);
            background: ${({ theme }) => theme.fullDark};
            border-radius: 15px;
        }

        #contact-contact-content {
            display: flex;
            flex-direction: row;
            margin-left: 10vh;
            margin-right: 10vh;
            margin-top: 8vh;
            height: 100%;
            width: 100%;
            justify-content: center;
        }

        h3 {
            font-size: 1.2vw;
        }

        h4 {
            font-size: 1.05vw;
        }

        #contact-contact-content-text {
            height: 100%;
            width: 30%;
        }

        #contact-contact-content-map {
            display: flex;
            margin-left: 4vh;
            margin-right: 4vh;
            width: 50%;
        }

        #contact-map {
            height: 120%;
            border-radius: 5px;
        }

        #contact-contact-content-info {
            height: 100%;
            width: 20%;
            p {
                font-weight: 0.75vw;
            }
        }

        #contact-contact-content-text-logo {
            height: 6.5vmin;
            width: 20vmin;
        }

        #contact-addressLink:hover {
            text-decoration: underline;
        }

        #background-contact-top {
            height: 25%;
            width: 100%;
        }

        #background-contact-bottom {
            height: 80%;
            width: 100%;
        }
    }
`;

export default StyledContact;
