import { SvgLoader } from 'react-svgmt';

import StyledCard from './Card.styled';

type Props = {
  picture: string;
  title: string;
  text: string;
  size: number;
};

function Card({ picture, title, text, size }: Props): JSX.Element {
  return (
    <StyledCard size={size}>
      <div className="card-sizing" />
      <div className="card-content">
        <div className="card-picture-container">
          <SvgLoader src={picture} className="card-picture" />
        </div>
        <div className="card-bottom">
          <h2 className="capitalize card-title">{title}</h2>
          <p className="card-text">{text}</p>
        </div>
      </div>
    </StyledCard>
  );
}

export default Card;
