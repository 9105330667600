import styled from 'styled-components';

import { isMenuOpenType } from '../..';

interface Props {
    open: isMenuOpenType;
}

export const StyledMenu = styled.nav<Props>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: ${({ theme }) => theme.fullDark};
    text-align: center;
    position: fixed;
    top: ${({ theme }) => theme.headerHeightMobile};
    transition: transform 0.3s ease-in-out;

    width: 100%;

    transform: ${({ open }) => (open ? 'translateX(0%)' : 'translateX(100%)')};

    .menu-link-text,
    p {
        color: white;
        letter-spacing: 0.45vw;
        text-decoration: none;
        font-size: ${({ theme }) => `${theme.headerHeightPercentRawMobile * 0.25}${theme.headerHeightMobileUnit}`};
        text-align: left;
    }

    .menu-link-node {
        display: flex;
        justify-content: space-around;
        flex-direction: column;
        width: 100%;
        height: ${({ theme }) => `${theme.headerHeightPercentRawMobile * 0.83}${theme.headerHeightMobileUnit}`};
        overflow: visible;
    }

    .menu-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding-left: 3vw;
    }

    .menu-link-content {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .menu-link-icon {
        --icon-size: ${({ theme }) => `${theme.headerHeightPercentRawMobile * 0.3}${theme.headerHeightMobileUnit}`};
        height: var(--icon-size);
        width: var(--icon-size);
        padding-right: 3vw;
    }

    .menu-link-arrow {
        --icon-size: ${({ theme }) => `${theme.headerHeightPercentRawMobile * 0.25}${theme.headerHeightMobileUnit}`};
        height: var(--icon-size);
        width: var(--icon-size);
        padding-right: 3vw;
    }

    #menu-bottom {
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        height: ${({ theme }) => `${theme.headerHeightPercentRawMobile * 0.6}${theme.headerHeightMobileUnit}`};
    }

    .separator {
        background-color: ${({ theme }) => `${theme.primaryLight}22`};
        height: 0.1vw;
        width: 100%;
    }
`;
