import { MapContainer, TileLayer, Marker, useMapEvents } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import _ from 'lodash';
import { useState } from 'react';

type PinProps = {
  position: [number, number];
  icon: string;
  iconSize: [number, number];
  onPress?: () => void;
  displayConditions?: { maxZoom?: number; minZoom?: number };
};

type MarkerProps = {
  pin: PinProps;
};

type Props = {
  center: [number, number];
  pins: PinProps[];
  zoom: number;
  maxZoom: number;
  id: string;
};

function Pin({ pin }: MarkerProps): JSX.Element | null {
  const [displayed, setDisplayed] = useState(true);

  const map = useMapEvents({
    zoomend: () => {
      const minOK = pin.displayConditions?.minZoom === undefined || map.getZoom() >= pin.displayConditions.minZoom;
      const maxOK = pin.displayConditions?.maxZoom === undefined || map.getZoom() <= pin.displayConditions.maxZoom;
      setDisplayed(minOK && maxOK);
    }
  });
  return displayed ? (
    <Marker
      position={pin.position}
      icon={L.icon({ iconUrl: pin.icon, iconSize: pin.iconSize })}
      eventHandlers={{ click: () => pin.onPress && pin.onPress() }}
    />
  ) : null;
}

function Map({ center, pins, zoom, maxZoom, id }: Props): JSX.Element {
  return (
    <MapContainer center={center} zoom={zoom} minZoom={4} maxZoom={maxZoom} zoomControl={false} scrollWheelZoom id={id}>
      <TileLayer
        // eslint-disable-next-line max-len
        attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
        url="https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png"
      />
      {_.reduce(
        pins,
        (acc: JSX.Element[], pin: PinProps): JSX.Element[] => {
          const p = <Pin pin={{ ...pin }} key={pin.icon} />;
          return p !== null ? [...acc, p] : acc;
        },
        []
      )}
    </MapContainer>
  );
}

export default Map;
