import React, { useEffect, useState, useMemo } from 'react';
import { SvgLoader } from 'react-svgmt';
import { Link } from 'react-scroll';

import StyledButton from './Button.styled';
import NoStyledButton from './NoButton.styled';

import info from '../../config/doNow-info.json';
import dialogIcon from '../../assets/icons/dialog.svg';
import { MyContext } from '../../page/Main';

export enum ActionType {
  MAIL,
  LINK,
  EXT_LINK,
  NONE
}

type Props = {
  children: JSX.Element;
  color?: string;
  actionType?: ActionType;
  actionPayload?: string | null;
  hideButton?: boolean;
};

const linkToDefault = 'contact';
const mailToDefault = info.mail;
const extLinkDefault = '';

function Button({
  children,
  color = undefined,
  actionType = ActionType.NONE,
  actionPayload = null,
  hideButton = false
}: Props): JSX.Element {
  const { headerHeightPx } = React.useContext(MyContext);
  const [action, setAction] = useState('');
  const [child, setChild] = useState(children);

  useEffect(() => {
    switch (actionType) {
      case ActionType.MAIL:
        setAction(actionPayload || mailToDefault);
        break;
      case ActionType.LINK:
        setAction(actionPayload || linkToDefault);
        break;
      case ActionType.EXT_LINK:
        setAction(actionPayload || extLinkDefault);
        break;
      default:
        setAction('');
    }
  }, [actionType, actionPayload]);

  const href = useMemo((): string | undefined => {
    switch (actionType) {
      case ActionType.MAIL:
        return `mailto:${action}`;
      case ActionType.EXT_LINK:
        return action;
      default:
        return undefined;
    }
  }, [action, actionType]);

  const parentProps = useMemo(
    () => ({
      color: !hideButton && !color ? undefined : color || '',
      href,
      target: actionType === ActionType.EXT_LINK ? '_blanc' : undefined,
      hideButton
    }),
    [hideButton, color, href, actionType]
  );

  const childProps = useMemo(
    () => ({
      href: parentProps.href,
      target: parentProps.target
    }),
    [parentProps]
  );

  useEffect(() => {
    if (React.isValidElement(children)) {
      setChild(React.cloneElement(children, { ...childProps }));
    }
  }, [childProps, children]);

  return hideButton ? (
    <NoStyledButton {...parentProps}>{child}</NoStyledButton>
  ) : (
    <StyledButton {...parentProps}>
      {actionType === ActionType.LINK && (
        <Link spy smooth activeClass="button-activeLink" to={action} className="button-link" offset={-headerHeightPx} />
      )}
      <SvgLoader id="button-icon" path={dialogIcon} />
      <div id="button-padding" />
      <p id="button-text" className="whiteText capitalize">
        {child}
      </p>
    </StyledButton>
  );
}
Button.defaultProps = { color: undefined, actionType: ActionType.NONE, actionPayload: null, hideButton: false };

export default Button;
