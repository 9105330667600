/* eslint-disable import/no-unresolved */
import _ from 'lodash';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper';
import 'swiper/css';

export type Props = {
  logos: { [key: string]: string };
};

function LogoCarousel({ logos }: Props): JSX.Element {
  return (
    <Swiper
      slidesPerView={1}
      id="carousel"
      modules={[Autoplay]}
      autoplay={{ delay: 1500, disableOnInteraction: false }}
    >
      {_.map(
        logos,
        (logo: string, name: string): JSX.Element => (
          <SwiperSlide key={name} className="carousel-cell">
            <div className="carousel-cell-content">
              <img className="carousel-cell-content-svg" src={logo} alt={logo} />
            </div>
          </SwiperSlide>
        )
      )}
    </Swiper>
  );
}

export default LogoCarousel;
