import StyledLegals from './Legals.styled';

export default (): JSX.Element => {
  return (
    <StyledLegals>
      <h1># Mentions légales</h1>
      <h2># 1 - Édition du site</h2>
      <p>
        En vertu de l&lsquo;article{' '}
        <a href="https://www.legifrance.gouv.fr/affichTexte.do?cidTexte=JORFTEXT000000801164#LEGIARTI000042038977">
          6 de la loi n° 2004-575 du 21 juin 2004
        </a>
        , pour la confiance dans l&lsquo;économie numérique, il est précisé aux utilisateurs du site internet{' '}
        <a href="https://do-now.io/">DoNow</a> l&lsquo;identité des différents intervenants dans le cadre de sa
        réalisation et de son suivi: Propriétaire du site DONOW Contact :contact@do-now.io -07 56 86 58 07- Adresse :608
        route du Soly 69170 Saint-Clément-sur-Valsonne. Identification de l&lsquo;entreprise :SAS DONOW au capital
        social de 10000€ - SIREN :851300228- RCS ou RM :Villefranche sur Saône-Tarare B 851 300 228- Adresse postale
        :608 route du Soly 69170 Saint-Clément-sur-Valsonne. Directeur de la publication :- Contact :Tanguy Charon -
        contact@do-now.io. Hébergeur :OVH SAS - 2 rue Kellermann - BP 80157 - 59053 Roubaix Cedex 1 - Téléphone : 1007.
        Délégué à la protection des données :Tanguy Charon contact@do-now.io.
      </p>
      <h2># 2 - Propriété intellectuelle et contrefaçons.</h2>
      <p>
        DONOW est propriétaire des droits de propriété intellectuelle et détient les droits d’usage sur tous les
        éléments accessibles sur le site internet, notamment les textes, images, graphismes, logos, vidéos,
        architecture, icônes et sons. Toute reproduction, représentation, modification, publication, adaptation de tout
        ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation
        écrite préalable de DONOW. Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il
        contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des
        articles L.335-2 et suivants du Code de
        <a href="https://www.legifrance.gouv.fr/codes/article_lc/LEGIARTI000032655082/"> Propriété Intellectuelle</a>.
      </p>
      <h2># 3 - Limitations de responsabilité.</h2>
      <p>
        DONOW ne pourra être tenu pour responsable des dommages directs et indirects causés au matériel de
        l’utilisateur, lors de l’accès au site <a href="https://do-now.io/">DoNow</a>. DONOW décline toute
        responsabilité quant à l’utilisation qui pourrait être faite des informations et contenus présents sur{' '}
        <a href="https://do-now.io/">DoNow</a>. DONOW s’engage à sécuriser au mieux le site{' '}
        <a href="https://do-now.io/">DoNow</a>, cependant sa responsabilité ne pourra être mise en cause si des données
        indésirables sont importées et installées sur son site à son insu. Des espaces interactifs (espace contact ou
        commentaires) sont à la disposition des utilisateurs. DONOW se réserve le droit de supprimer, sans mise en
        demeure préalable, tout contenu déposé dans cet espace qui contreviendrait à la législation applicable en
        France, en particulier aux dispositions relatives à la protection des données. Le cas échéant, DONOW se réserve
        également la possibilité de mettre en cause la responsabilité civile et/ou pénale de l’utilisateur, notamment en
        cas de message à caractère raciste, injurieux, diffamant, ou pornographique, quel que soit le support utilisé
        (texte, photographie …).
      </p>
      <h2># 4 - CNIL et gestion des données personnelles.</h2>
      <p>
        Conformément aux dispositions de{' '}
        <a href="https://www.legifrance.gouv.fr/affichTexte.do?cidTexte=JORFTEXT000000886460">
          la loi 78-17 du 6 janvier 1978
        </a>
        , l’utilisateur du site <a href="https://do-now.io/">DoNow</a> dispose d’un droit d’accès, de modification et de
        suppression des informations collectées. Pour exercer ce droit, envoyez un message à notre Délégué à la
        Protection des Données : Tanguy Charon - contact@do-now.io. Pour plus d&lsquo;informations sur la façon dont
        nous traitons vos données (type de données, finalité, destinataire...), lisez notre{' '}
        <a href="https://do-now.io/">DoNow</a>.
      </p>
      <h2># 5 - Liens hypertextes et cookies</h2>
      <p>
        Le site <a href="https://do-now.io/">DoNow</a> contient des liens hypertextes vers d’autres sites et dégage
        toute responsabilité à propos de ces liens externes ou des liens créés par d’autres sites vers{' '}
        <a href="https://do-now.io/">DoNow</a>. La navigation sur le site <a href="https://do-now.io/">DoNow</a> est
        susceptible de provoquer l’installation de cookie(s) sur l’ordinateur de l’utilisateur. Un cookie est un fichier
        de petite taille qui enregistre des informations relatives à la navigation d’un utilisateur sur un site. Les
        données ainsi obtenues permettent d&lsquo;obtenir des mesures de fréquentation, par exemple. Vous avez la
        possibilité d’accepter ou de refuser les cookies en modifiant les paramètres de votre navigateur. Aucun cookie
        ne sera déposé sans votre consentement. Les cookies sont enregistrés pour une durée maximale de 13 mois. Pour
        plus d&lsquo;informations sur la façon dont nous faisons usage des cookies, lisez notre{' '}
        <a href="https://do-now.io/">DoNow</a>. Do-Now.io n&lsquo;utilise cependant aucun cookie.
      </p>

      <h2># 6 - Droit applicable et attribution de juridiction.</h2>
      <p>
        Tout litige en relation avec l’utilisation du site <a href="https://do-now.io/">DoNow</a> est soumis au droit
        français. En dehors des cas où la loi ne le permet pas, il est fait attribution exclusive de juridiction aux
        tribunaux compétents de Lyon.
      </p>
    </StyledLegals>
  );
};
