import { useState, useEffect } from 'react';

import constProps from '../config/const';

function getWindowDimensions(): { width: number; height: number } {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

function Window(): { isMobile: boolean; width: number; height: number } {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize(): void {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return {
        isMobile: windowDimensions.width <= +constProps.maxMobileRawWidth,
        width: windowDimensions.width,
        height: windowDimensions.height
    };
}

export default Window;
