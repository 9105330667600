import addressBlack from './addressBlack.svg';
import addressWhite from './addressWhite.svg';
import contact from './contact.svg';
import contactActive from './contactActive.svg';
import contactActiveHover from './contactActiveHover.svg';
import contactHover from './contactHover.svg';
import dialog from './dialog.svg';
import doubleArrowDown from './doubleArrowDown.svg';
import expertise from './expertise.svg';
import expertiseActive from './expertiseActive.svg';
import expertiseActiveHover from './expertiseActiveHover.svg';
import expertiseHover from './expertiseHover.svg';
import formation from './formation.svg';
import formationActive from './formationActive.svg';
import formationActiveHover from './formationActiveHover.svg';
import formationHover from './formationHover.svg';
import hamburger from './hamburger.svg';
import home from './home.svg';
import homeActive from './homeActive.svg';
import homeActiveHover from './homeActiveHover.svg';
import homeHover from './homeHover.svg';
import linkedin from './linkedin.svg';
import location from './location.jpg';
import mail from './mail.svg';
import simpleRightArrow from './simpleRightArrow.svg';
import simpleDownArrow from './simpleDownArrow.svg';
import tel from './tel.svg';
import youtube from './youtube.svg';

const icons: { [key: string]: string } = {
    addressBlack,
    addressWhite,
    contact,
    contactActive,
    contactActiveHover,
    contactHover,
    dialog,
    doubleArrowDown,
    expertise,
    expertiseActive,
    expertiseActiveHover,
    expertiseHover,
    formation,
    formationActive,
    formationActiveHover,
    formationHover,
    hamburger,
    home,
    homeActive,
    homeActiveHover,
    homeHover,
    linkedin,
    location,
    mail,
    simpleRightArrow,
    simpleDownArrow,
    tel,
    youtube
};

export default icons;
