import styled from 'styled-components';

interface Props {
    backgroundColor: string;
    height: string;
    screenWidth: number;
}

const StyledLogoCarousel = styled.div<Props>`
    width: ${({ screenWidth }) => screenWidth}px;
    height: ${({ height }) => height};
    background-color: ${({ theme }) => theme.primaryLight};
    display: flex;

    .carousel {
    }

    .carousel-cell {
        background-color: ${({ theme }) => theme.primaryLight};
        justify-content: center;
        align-items: center;
        display: flex;
    }

    .carousel-cell-content {
        height: 95%;
        width: 70%;
        justify-content: center;
        align-items: center;
        display: flex;
    }

    .carousel-cell-content-grey {
        opacity: 0.7;
    }

    .carousel-cell-content-svg {
        height: 80%;
        width: 90%;
    }

    .carousel-cell.is-selected {
        background: #ed2;
    }

    @media only screen and (min-width: ${({ theme }) => theme.maxMobileRawWidth + 1}px) {
        position: static;
        justify-content: space-around;
        align-items: center;
        width: 100%;

        #carousel-web-container {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding-right: 4vmin;
            padding-left: 4vmin;
            padding-top: 1vmin;
            padding-bottom: 1vmin;
        }

        .carousel-cell {
            width: ${({ theme }) => 80 / theme.logoCarouselNumberLogosDisplayedWeb}%;
        }

        .carousel-cell-content {
            height: 100%;
            width: 100%;
        }
    }
`;

export default StyledLogoCarousel;
