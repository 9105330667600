import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';
import { SvgLoader } from 'react-svgmt';
import { useTranslation } from 'react-i18next';

import { MyContext } from '../Main';
import Background from '../../component/Background.styled';
import StyledHome from './Home.styled';
import DonowPlanet from '../../component/DonowPlanet';

import planetCircled from '../../assets/pictures/planetCircled.svg';
import doubleArrow from '../../assets/icons/doubleArrowDown.svg';
import planetOrange from '../../assets/pictures/planets/planetOrange.svg';
import planetBlue from '../../assets/pictures/planets/planetBlue.svg';
import planetWhite from '../../assets/pictures/planets/planetWhite.svg';
import constProps from '../../config/const';

type Props = {
  pageHeight: number;
  nextPage: string;
};

function Home({ pageHeight, nextPage }: Props | any): JSX.Element {
  const { isMobile, headerHeightPx } = React.useContext(MyContext);
  const { t } = useTranslation();
  const [isArrowMoving, moveArrow] = useState(false);

  useEffect(() => {
    const intervale = setInterval(() => {
      moveArrow(true);
      setTimeout(() => moveArrow(false), 0.7 * 1000);
    }, 3000);
    return () => clearInterval(intervale);
  }, []);

  const floatingPlanetStyle = {
    size: (n: number) => ({ height: `${10 + n}vmin`, width: `${10 + n}vmin` }),
    style: {
      position: 'absolute' as const,
      zIndex: constProps.zIndexPage + 5
    }
  };

  const processTitle = (): JSX.Element[] => {
    const titleWords = t('View.Home.mainText').split(' ');
    const titleLines = titleWords.reduce((acc: string[], word: string) => {
      const idx = acc.length - 1;
      if (acc.length !== 0 && acc[idx].length + word.length + 1 < constProps.titleTextMaxLineLength) {
        return [...acc.slice(0, idx), `${acc[idx]} ${word}`];
      }
      return [...acc, word];
    }, []);
    return titleLines.map((line) => (
      <h1 key={line} className="home-mainText whiteText">
        {line}
      </h1>
    ));
  };

  return (
    <StyledHome className="pageFragment">
      <div id="home-planet">
        {isMobile ? (
          <img className="home-planet-element" id="home-planet-svg" src={planetCircled} alt="" />
        ) : (
          <DonowPlanet />
        )}
        <div id="home-title" className="home-planet-element">
          {processTitle()}
        </div>
      </div>
      {isMobile && (
        <>
          <img
            src={planetOrange}
            alt="planetOrange"
            style={{ ...floatingPlanetStyle.style, top: '22%', left: '20%', ...floatingPlanetStyle.size(-2) }}
          />
          <img
            src={planetBlue}
            alt="planetBlue"
            style={{ ...floatingPlanetStyle.style, bottom: '22%', right: '20%', ...floatingPlanetStyle.size(-2) }}
          />
          <img
            src={planetWhite}
            alt="planetWhite"
            style={{ ...floatingPlanetStyle.style, bottom: '35%', right: '13%', ...floatingPlanetStyle.size(-7) }}
          />
          <img
            src={planetWhite}
            alt="planetWhite"
            style={{ ...floatingPlanetStyle.style, top: '27%', right: '35%', ...floatingPlanetStyle.size(-8.3) }}
          />
          <img
            src={planetWhite}
            alt="planetWhite"
            style={{ ...floatingPlanetStyle.style, bottom: '27%', left: '23%', ...floatingPlanetStyle.size(-8.3) }}
          />
        </>
      )}
      <Link
        spy
        smooth
        to={nextPage}
        className={`home-arrowNextPage${isArrowMoving ? '-move' : ''}`}
        offset={-headerHeightPx}
      >
        <SvgLoader path={doubleArrow} />
      </Link>
      {isMobile ? <Background height={pageHeight} className="background-home" /> : null}
    </StyledHome>
  );
}

export default Home;
