import React, { useState, useEffect } from 'react';
import { Element } from 'react-scroll';
import { ThemeProvider } from 'styled-components';
import { SvgLoader } from 'react-svgmt';
import { useTranslation } from 'react-i18next';

import constProps from '../../config/const';

import info from '../../config/doNow-info.json';
import youtubeLogo from '../../assets/icons/youtube.svg';

import Page from '../../component/Page.styled';
import StyledMain from './Main.styled';

import Home from '../Home';
import Expertise from '../Expertise';
import Formation from '../Formation';
import Contact from '../Contact';
import Header from '../../component/Header';
import Footer from '../../component/Footer';

type Props = {
  winHeight: number;
  headerHeightPx: number;
  handleScrollMove: boolean;
  resetHandleScrollMove: () => void;
  isOnTop: boolean;
  isMobile: boolean;
  winWidth: number;
};

export const MyContext = React.createContext({
  winWidth: 0,
  winHeight: 0,
  headerHeightPx: 0,
  isMobile: false,
  vminInPx: 0,
  ratio: 0
});

function Main({
  winHeight,
  headerHeightPx,
  handleScrollMove,
  resetHandleScrollMove,
  isOnTop,
  isMobile,
  winWidth
}: Props): JSX.Element {
  const { t } = useTranslation();

  const pages = [
    {
      name: 'home',
      link: 'home',
      component: <Home />,
      webHeight: winHeight,
      mobileHeight: winHeight
    },
    {
      name: 'expertise',
      link: 'expertise',
      component: <Expertise />
    },
    {
      name: 'formation',
      link: 'formation',
      component: <Formation />
    },
    {
      name: 'contact',
      link: 'footer',
      component: <Contact />
    }
  ];

  const [ratio, setRatio] = useState(Math.abs(winHeight / winWidth));

  useEffect(() => {
    setRatio(Math.abs(winHeight / winWidth));
  }, [winWidth, winHeight]);

  const printWebSite = (): JSX.Element => {
    return (
      <MyContext.Provider
        value={{
          winWidth,
          winHeight,
          headerHeightPx,
          isMobile,
          vminInPx: (winWidth < winHeight ? winWidth : winHeight) / 100,
          ratio
        }}
      >
        <ThemeProvider theme={constProps}>
          <Header
            pages={pages.map((part) => ({ name: part.name, link: part.link, hover: part.name }))}
            info={{
              tel: info.tel,
              mail: info.mail,
              linkedinLink: info.linkedinLink
            }}
            handleScrollMove={handleScrollMove}
            resetHandleScrollMove={resetHandleScrollMove}
            isOnTop={isOnTop}
          />
          <StyledMain className="content" isMobile={isMobile}>
            {pages.map((part, i) => {
              const pageHeight = isMobile ? part.mobileHeight : part.webHeight;
              return (
                <Page pageHeight={pageHeight} key={`page-${part.name}`}>
                  <Element style={{ height: '100%', width: '100%' }} name={part.name} className="pageLink">
                    {React.cloneElement(part.component, {
                      nextPage: i + 1 < pages.length ? pages[i + 1].name : undefined,
                      footerLink:
                        i + 1 === pages.length ? (
                          <Element style={{ height: '100%', width: '100%' }} name="footer" className="pageLink" />
                        ) : undefined,
                      pageHeight
                    })}
                  </Element>
                </Page>
              );
            })}
            {!isMobile && (
              <a href={info.youtubeLink} target="_blank" rel="noopener noreferrer">
                <div id="home-youtubeNode">
                  <SvgLoader id="home-youtubeLogo" path={youtubeLogo} />
                  <p id="home-youtubeText">
                    {t('View.Home.youtubeText1')}
                    <b>{t('View.Home.youtubeText2')}</b>
                    {t('View.Home.youtubeText3')}
                  </p>
                </div>
              </a>
            )}
            <Footer height={(winHeight / 100) * 7} />
          </StyledMain>
        </ThemeProvider>
      </MyContext.Provider>
    );
  };

  return ratio <= constProps.maxRatio ? printWebSite() : printWebSite();
}

export default Main;
