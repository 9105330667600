import React from 'react';
import { useTranslation } from 'react-i18next';
import { SvgLoader } from 'react-svgmt';

import _ from 'lodash';
import Button, { ActionType as ButtonActionType } from '../../component/Button';
import Card from '../../component/Card';

import Background from '../../component/Background.styled';
import StyledExpertise from './Expertise.styled';

import constProps from '../../config/const';

import devopsPicture from '../../assets/pictures/devopsCardPicture.svg';
import developpementPicture from '../../assets/pictures/developpementCardPicture.svg';
import gestionPicture from '../../assets/pictures/gestionCardPicture.svg';
import planetCircledOrange from '../../assets/pictures/planetCircledOrange.svg';
import planetCircledBlue from '../../assets/pictures/planetCircledBlue.svg';
import { MyContext } from '../Main';

type Props = {
  pageHeight: number;
};

function Expertise({ pageHeight, ...styleProps }: Props | any): JSX.Element {
  const { isMobile } = React.useContext(MyContext);
  const { t } = useTranslation();

  const cardsContent = [
    {
      picture: devopsPicture,
      title: t('View.Expertise.techno.devopsCardTitle'),
      text: t('View.Expertise.techno.devopsCardText')
    },
    {
      picture: developpementPicture,
      title: t('View.Expertise.techno.developpementCardTitle'),
      text: t('View.Expertise.techno.developpementCardText')
    },
    {
      picture: gestionPicture,
      title: t('View.Expertise.techno.gestionCardTitle'),
      text: t('View.Expertise.techno.gestionCardText')
    }
  ];

  const printContactButton = (): JSX.Element => (
    <Button color={constProps.primaryBlue} actionType={ButtonActionType.MAIL}>
      {t('View.Expertise.expertise.buttonText')}
    </Button>
  );

  const floatingPlanetStyle = {
    position: 'absolute',
    zIndex: constProps.zIndexPage - 5
  };

  return (
    <StyledExpertise className="pageFragment" {...styleProps}>
      <div className="pageSample">
        <div className="sample-content">
          <div className="title1-container">
            <div>
              <h1 className="capitalize whiteText title1">{t('View.Expertise.expertise.title')}</h1>
              <h2 className="capitalize blueText subTitle1">{t('View.Expertise.expertise.subTitle')}</h2>
            </div>
            {!isMobile && printContactButton()}
          </div>
          <div className="text-container">
            <p className="whiteText text">{t('View.Expertise.expertise.p1')}</p>
            <div className="br">
              <br />
            </div>
            <p className="whiteText text">{t('View.Expertise.expertise.p2')}</p>
            <div className="br">
              <br />
            </div>
            <p className="whiteText text">{t('View.Expertise.expertise.p3')}</p>
            <div className="br">
              <br />
            </div>
            <p className="whiteText text bolder">{t('View.Expertise.expertise.p4')}</p>
            <div className="br">
              <br />
            </div>
            <p className="whiteText text bolder">{t('View.Expertise.expertise.p5')}</p>
          </div>
          {isMobile && printContactButton()}
        </div>
        <div id="expertise-content-techno">
          <h1 className="capitalize darkerGreyText title2">{t('View.Expertise.techno.title')}</h1>
          <h2 className="capitalize darkerGreyText subTitle2">{t('View.Expertise.techno.subTitle')}</h2>
          <div id="expertise-content-techno-card-container">
            {_.map(cardsContent, (props) => (
              <Card key={props.title} {...props} size={isMobile ? constProps.mobileWidthPercentRaw : 18} />
            ))}
          </div>
        </div>
      </div>
      {!isMobile && (
        <>
          <SvgLoader
            src={planetCircledOrange}
            alt="planetCircledOrange"
            style={{ ...floatingPlanetStyle, right: '-6%', top: '39%', height: '14vw' }}
          />
          <SvgLoader
            src={planetCircledBlue}
            alt="planetCircledBlue"
            style={{ ...floatingPlanetStyle, left: '-5%', top: '59%', height: '21vw' }}
          />
        </>
      )}
      <Background height={pageHeight}>
        <div id="background-expertise-top" />
        <div id="background-expertise-bottom" />
      </Background>
    </StyledExpertise>
  );
}

export default Expertise;
