/* eslint-disable react/no-array-index-key */
import { Line } from '@react-three/drei';
import * as THREE from 'three';
import { Vector3 } from 'three';

export default function MainPlanetRings(): JSX.Element {
  // const tubes = 0.015;
  const pos = [];
  // for (let index = -8; index < 8; index += 8 / 5) {
  //   pos.push(index + 0.8);
  // }

  const angles = [
    (5 * Math.PI) / 12,
    Math.PI / 3.5,
    Math.PI / 5,
    Math.PI / 8,
    Math.PI / 20,
    -Math.PI / 20,
    -Math.PI / 8,
    -Math.PI / 5,
    -Math.PI / 3.5,
    (-5 * Math.PI) / 12
  ];

  // const heights = angles.map((angle) => 8 * Math.cos(angle));

  const points: THREE.Vector3[][] = [];
  for (let i = 0; i < angles.length; i += 1) {
    const radius = 8 * Math.cos(angles[i]);

    const planet = new THREE.EllipseCurve(
      0.0,
      0.0, // Center x, y
      radius,
      radius, // x radius, y radius
      0.0,
      2.0 * Math.PI, // Start angle, stop angle
      false,
      0
    );
    const pt = planet.getSpacedPoints(256);
    const test = pt.map((p) => {
      return new Vector3(p.x, p.y, 0);
    });
    points.push(test);
    pos.push(8 * Math.sin(angles[i]));
    // const geo = new THREE.BufferGeometry().setFromPoints(pts);

    // const mat = new THREE.LineBasicMaterial({ color: 0xffffff });

    // const circle = new THREE.LineLoop(geo, mat);
    // circle.position.z = 8 * Math.sin(angles[i]);

    // mainPlanet.add(circle);
  }

  return (
    <>
      {pos.map((p, index) => (
        <Line
          points={points[index]}
          position={[0, 0, p]}
          color="white"
          lineWidth={0.5}
          key={`line${index}-${new Date().getTime()}`}
          alphaWrite
        >
          <meshBasicMaterial attach="material" color="white" />
        </Line>
      ))}
    </>
  );
}

//  </Line>
//  <Torus args={[heights[index], tubes, 30, 200]} position={[0, 0, p]}>
//    <meshBasicMaterial attach="material" />
//  </Torus>;
