import constProps from '../../config/const';
import LogoCarousel from '.';

import { svgLogos, pngLogos } from '../../assets/pictures/partnerLogos';

type Props = {
  isMobile: boolean;
  heightWeb: string;
  heightMobile: string;
  screenWidth: number;
};

function LogoCarouselPartners(props: Props): JSX.Element {
  return (
    <LogoCarousel {...props} backgroundColor={constProps.primaryLight} logosMobile={svgLogos} logosWeb={pngLogos} />
  );
}

export default LogoCarouselPartners;
