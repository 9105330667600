import styled from 'styled-components';

interface Props {
    color?: string | undefined;
}

const NoStyledButton = styled.a<Props>`
    cursor: pointer;

    :hover {
        text-decoration: underline;
        text-decoration-color: ${({ color, theme }) => color || theme.primaryLight};
    }
`;

export default NoStyledButton;
