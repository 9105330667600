import styled from 'styled-components';

type Props = {
    color: string;
};

const StyledPopUp = styled.div<Props>`
    .container {
        padding-left: 1em;
        padding-right: 1em;

        width: 15em;
        border-top: 0.3em solid;
        border-top-color: ${({ color }) => color};
        background-color: #202020;
    }
    .vertical_dotted_line {
        border-left: 1px dotted grey;
        height: 10px;
        margin-left: 50%;
    }
`;

export default StyledPopUp;
