import styled from 'styled-components';

interface Props {
    height?: number;
}

const Background = styled.div<Props>`
    display: flex;
    height: ${({ height }) => (height !== null && height !== undefined ? `${height}px` : '100%')};
    width: 100%;
    z-index: ${({ theme }) => theme.zIndexBackground};
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    flex-direction: column;
`;

export default Background;
