import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';

import { infoType, pagesType } from '..';

import StyledHeader from '../Header.styled';

import logo from '../../../assets/pictures/doNowLogo.svg';
import icons from '../../../assets/icons';
import { MyContext } from '../../../page/Main';
import Button, { ActionType as ButtonActionType } from '../../Button';

type Props = {
  info: infoType;
  pages: pagesType;
};

function Header({ info, pages }: Props): JSX.Element {
  const { headerHeightPx } = React.useContext(MyContext);
  const { t } = useTranslation();
  const [activePage, setActivePage] = useState('');
  const [linkHover, setLinkHover] = useState('');

  const setHover = (link: string): void => setLinkHover(link);

  const unsetHover = (): void => setLinkHover('');

  return (
    <StyledHeader isOnTop isMenuOpen>
      <nav className="header-links header-container">
        {pages.map(({ name, link, hover }) => (
          <div key={name} onMouseEnter={() => setHover(name)} onMouseLeave={unsetHover}>
            <Link
              spy
              smooth
              activeClass="header-activeLink"
              to={link}
              className="header-link header-link-cursor header-node"
              offset={-headerHeightPx}
              onSetActive={(to: string) =>
                setActivePage(pages.reduce((acc, page) => (page.name === to ? page.name : acc), ''))
              }
              onSetInactive={() => {}}
            >
              {name === activePage && name === linkHover ? (
                <img className="header-icon" src={icons[`${name}ActiveHover`]} alt={`icon-${name}ActiveHover`} />
              ) : name === activePage ? (
                <img className="header-icon" src={icons[`${name}Active`]} alt={`icon-${name}Active`} />
              ) : name === linkHover ? (
                <img className="header-icon" src={icons[`${name}Hover`]} alt={`icon-${name}Hover`} />
              ) : (
                <img className="header-icon" src={icons[name]} alt={`icon-${name}`} />
              )}
              <p>{t(`View.Header.${name}`)}</p>
            </Link>
            <Link
              spy
              smooth
              to={hover}
              onSetActive={(to: string) =>
                setActivePage(pages.reduce((acc, page) => (page.name === to ? page.name : acc), ''))
              }
              onSetInactive={() => {}}
            />
          </div>
        ))}
      </nav>
      <Link smooth to={pages[0].name} className="header-link-cursor">
        <div onClick={() => {}} onKeyDown={() => {}} role="button" tabIndex={0}>
          <img className="header-logo" src={logo} alt="DoNow logo" data-testid="mobile-header-logo" />
        </div>
      </Link>
      <div className="header-info header-container">
        <div className="header-info-container">
          <div />

          <div className="header-node">
            <img className="header-icon" src={icons.tel} alt="" />
            <p className="header-text">{info.tel}</p>
          </div>
          <Button actionType={ButtonActionType.MAIL} hideButton>
            <div className="header-node">
              <img className="header-icon" src={icons.mail} alt="" />
              <p className="header-text">{info.mail}</p>
            </div>
          </Button>
          <a id="header-linkedin-icon-container" href={info.linkedinLink} target="_blank" rel="noopener noreferrer">
            <img id="header-linkedin-icon" className="header-icon" src={icons.linkedin} alt="linkedin logo" />
          </a>
        </div>
      </div>
    </StyledHeader>
  );
}

export default Header;
