import React, { useState } from 'react';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';

import { isMenuOpenType, setIsMenuOpenType } from '../..';
import { infoType, pagesType } from '../../..';

import { StyledMenu } from './Menu.styled';

import icons from '../../../../../assets/icons';
import { MyContext } from '../../../../../page/Main';

type Props = {
  isOpen: isMenuOpenType;
  setIsOpen: setIsMenuOpenType;
  info: infoType;
  pages: pagesType;
};

function Menu({ isOpen, setIsOpen, info, pages }: Props): JSX.Element {
  const { headerHeightPx } = React.useContext(MyContext);
  const { t } = useTranslation();
  const [activePage, setActivePage] = useState('');

  return (
    <StyledMenu open={isOpen}>
      {pages.map(({ name, link, hover }) => (
        <div className="menu-link-node" key={name}>
          <Link
            spy
            smooth
            activeClass="header-activeLink"
            to={link}
            className="menu-link"
            offset={-headerHeightPx}
            onSetActive={(to: string) =>
              setActivePage(pages.reduce((acc, page) => (page.name === to ? page.name : acc), ''))
            }
            onSetInactive={() => {}}
            onClick={() => setIsOpen(false)}
          >
            <div className="menu-link-content">
              {name === activePage ? (
                <img className="menu-link-icon" src={icons[`${name}Active`]} alt={`icon-${name}Active`} />
              ) : (
                <img className="menu-link-icon" src={icons[name]} alt={`icon-${name}`} />
              )}
              <p className="menu-link-text">{t(`View.Header.${name}`)}</p>
            </div>
            <img className="menu-link-arrow" src={icons.simpleRightArrow} alt={`arrow-${name}`} />
          </Link>
          <Link
            spy
            smooth
            to={hover}
            onSetActive={(to: string) =>
              setActivePage(pages.reduce((acc, page) => (page.name === to ? page.name : acc), ''))
            }
            onSetInactive={() => {}}
          />
          {/* eslint-disable-next-line max-len */}
          {/* <Link className="menu-link" onClick={() => setIsOpen(false)} spy smooth to={link} offset={-headerHeightPx}>
            <div className="menu-link-content">
              <img className="menu-link-icon" src={icons[name]} alt={`icon-${name}`} />
              <p className="menu-link-text">{t(`View.Header.${name}`)}</p>
            </div>
            <img className="menu-link-arrow" src={icons.simpleRightArrow} alt={`arrow-${name}`} />
          </Link> */}
          <div className="separator" />
        </div>
      ))}
      <div id="menu-bottom">
        <a href={`tel:${info.tel.replaceAll(' ', '-').replace('0', '+33-')}`}>
          <img className="menu-link-icon" src={icons.tel} alt="icon-tel" />
        </a>
        <a href={`mailto:${info.mail}`}>
          <img className="menu-link-icon" src={icons.mail} alt="icon-mail" />
        </a>
        <a href={info.linkedinLink} target="_blank" rel="noopener noreferrer" className="capitalize">
          <img className="menu-link-icon" src={icons.linkedin} alt="linkedin logo" />
        </a>
      </div>
    </StyledMenu>
  );
}

export default Menu;
